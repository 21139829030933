.rec-dot {
  width: 10px !important;
  height: 10px !important;
  box-shadow: none !important;
  border: none !important;
  background: #ffa9af !important;
}
.rec-dot_active {
  background-color: #8a3a22 !important;
}
.rec-slider-container {
  margin: 0 !important;
}
.rec-pagination {
  margin-top: 0 !important;
}

@media (max-width: 768px) {
  .rec-dot {
    width: 8px !important;
    height: 8px !important;
  }
  .rec-carousel-wrapper {
    flex-direction: column-reverse !important;
  }
  .rec-pagination {
    margin-bottom: 1rem !important;
  }
}
