.loginLandscape {
  background-image: url("../../assets/images/ashirvadLandscape.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loginPortrait {
  background-image: url("../../assets/images/ashirvadPortrait.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blur {
  backdrop-filter: blur(4px);
}
