@import url("https://fonts.googleapis.com/css2?family=Inter&family=Kameron:wght@400;700&display=swap");
body {
  font-family: "Inter", serif;
}

html {
  font-size: 1vw;
}

input:focus {
  outline: none;
}

.helloar-view-in-your-own-space-container {
  display: none !important;
}

/* scroll bar css */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}
